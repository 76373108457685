<form
  [formGroup]="principalForm"
  (ngSubmit)="onSubmit($event)"
>
  <div class="spot-modal--header">{{text.title()}}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body op-form">
    <op-form-field
      [label]="textLabel"
      required
    >
      <op-ium-principal-search
        *ngIf="!(hasPrincipalSelected && isNewPrincipal)"
        [opFormBinding]="principalControl"
        [type]="type"
        [project]="project"
        slot="input"
        (createNew)="createNewFromInput($event)"
      ></op-ium-principal-search>

      <p
        *ngIf="isNewPrincipal && type === PrincipalType.User"
        slot="input"
      >
        <b>{{ text.inviteUser }}</b> {{ principal.name }}
        <button
          type="button"
          class="spot-link"
          (click)="principalControl?.setValue(null)"
        >{{ text.change }}</button>
      </p>

      <p
        *ngIf="isNewPrincipal && type === PrincipalType.Placeholder"
        slot="input"
      >
        <b>{{ text.createNewPlaceholder }}</b> {{ principal.name }}
        <button
          type="button"
          class="spot-link"
          (click)="principalControl?.setValue(null)"
        >{{ text.change }}</button>
      </p>

      <div
        slot="errors"
        class="op-form-field--error"
        *ngIf="principalControl?.touched && principalControl?.invalid"
      >
        {{ text.required[type] }}
      </div>
    </op-form-field>

    <op-dynamic-form
      *ngIf="isNewPrincipal && type === PrincipalType.User && userDynamicFieldConfig.schema"
      [dynamicFormGroup]="dynamicFieldsControl"
      [settings]="userDynamicFieldConfig"
      [formUrl]="apiV3Service.users.form.path"
      [handleSubmit]="false"
    ></op-dynamic-form>
  </div>

  <div class="spot-modal--footer spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button button_no-margin spot-action-bar--action"
        (click)="back.emit()"
      >{{ text.backButton }}</button>
      <button class="button button_no-margin -highlight spot-action-bar--action">{{ text.nextButton }}</button>
    </div>
  </div>
</form>
