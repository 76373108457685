<div
  class="spot-modal wp-table--configuration-modal loading-indicator--location"
  data-indicator-name="modal"
>
  <div class="spot-modal--header">{{text.label_visibility_settings}}</div>

  <div class="spot-divider"></div>

  <query-sharing-form
    class="spot-modal--body"
    [isSave]="false"
    (onChange)="setValues($event)"
    [isStarred]="isStarred"
    [isPublic]="isPublic">
  </query-sharing-form>

  <div class="spot-modal--footer spot-action-bar">
    <div class="spot-action-bar--right">
      <button class="button button_no-margin -highlight -with-icon icon-checkmark spot-action-bar--action"
              (click)="saveQuery($event)"
              [textContent]="text.button_save">
      </button>
      <button class="button button_no-margin -with-icon icon-cancel spot-action-bar--action"
              [textContent]="text.button_cancel"
              [disabled]="isBusy"
              (click)="closeMe($event)">
      </button>
    </div>
  </div>
</div>
