<form
  class="spot-modal wp-table--configuration-modal loading-indicator--location"
  data-indicator-name="modal"
  name="modalSaveForm"
  (submit)="saveQueryAs($event)"
>
  <div class="spot-modal--header">{{text.save_as}}</div>

  <div class="spot-divider"></div>

  <div
    name="modalSaveForm"
    class="spot-modal--body form"
  >
    <div class="form--field -required">
      <label class="form--label" for="save-query-name" [textContent]="text.label_name"></label>
      <div class="form--field-container">
        <div class="form--text-field-container">
          <input
            class="form--text-field"
            type="text"
            name="save-query-name"
            id="save-query-name"
            #queryNameField
            [(ngModel)]="queryName"
            required
          />
        </div>
      </div>
    </div>

    <section class="form--section">
      <h3 [textContent]="text.label_visibility_settings"></h3>
      <query-sharing-form
        [isSave]="true"
        (onChange)="setValues($event)"
        [isStarred]="isStarred"
        [isPublic]="isPublic">
      </query-sharing-form>
    </section>
  </div>

  <div class="spot-modal--footer spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button button_no-margin -with-icon icon-cancel spot-action-bar--action"
        [textContent]="text.button_cancel"
        (click)="closeMe($event)"
      ></button>
      <button
        class="button button_no-margin -highlight -with-icon icon-checkmark spot-action-bar--action"
        [textContent]="text.button_save"
        [disabled]="isBusy || !queryName"
      ></button>
    </div>
  </div>
</form>
