<form
  class="spot-modal "
  (submit)="applyAndClose($event)"
>
  <div class="spot-modal--header">{{text.title}}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body">
    <textarea name="codemirror-element"
              id="codemirror-element"
              [ngModel]="content"
              #codeMirrorPane></textarea>
    <hr/>
    <div class="form--field">
      <label
        for="selected-language"
        [textContent]="text.language"
        class="form--label hidden-for-sighted">
      </label>
      <div class="form--field-container">
        <div class="form--select-container">
          <input
            #selectedLanguage
            tabindex="2"
            type="text"
            id="selected-language"
            name="selected-language"
            [ngModel]="language"
            (ngModelChange)="updateLanguage($event)"
            class="form--select"/>
        </div>
      </div>
      <p [textContent]="text.language_hint"></p>
    </div>
  </div>

  <div class="spot-modal--footer spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        class="spot-modal--cancel-button button button_no-margin spot-action-bar--action"
        (click)="closeMe($event)"
        tabindex="4"
        type="button"
        [textContent]="text.button_cancel"
      ></button>
      <button
        class="spot-modal--submit-button button button_no-margin -highlight spot-action-bar--action"
        tabindex="3"
        [textContent]="text.button_save"
      ></button>
    </div>
  </div>
</form>
