<div
  class="spot-modal confirm-form-submit--modal loading-indicator--location"
  [ngClass]="{'-highlight' : dangerHighlighting }"
  data-indicator-name="modal"
>
  <div class="spot-modal--header">{{text.title}}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body">
    <p>
      <span [textContent]="text.text"></span>
      <br/>
      <span *ngFor="let data of passedData">
        <br/>
        <strong>{{data}}</strong>
      </span>
    </p>
  </div>

  <div class="spot-modal--footer spot-action-bar">
    <div class="spot-action-bar--right">
      <button class="confirm-form-submit--continue button button_no-margin spot-action-bar--action"
              [ngClass]="dangerHighlighting ? '-danger': '-highlight'"
              (click)="confirmAndClose($event)"
              [textContent]="text.button_continue"
      ></button>
      <button class="confirm-form-submit--cancel button button_no-margin spot-action-bar--action"
              (click)="closeMe($event)"
              [textContent]="text.button_cancel"
      ></button>
    </div>
  </div>
</div>
