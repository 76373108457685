<form
  [formGroup]="messageForm"
  (ngSubmit)="onSubmit($event)"
>
  <div class="spot-modal--header">{{text.title()}}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body op-form">
    <op-form-field [label]="text.label">
      <p class="op-form-field--description" slot="description">
        {{ text.description() }}
      </p>
      <textarea
        class="op-input op-ium-invite-message-field"
        [formControl]="messageControl"
        slot="input"
        #input
        autofocus>
      </textarea>
    </op-form-field>
  </div>

  <div class="spot-modal--footer spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button button_no-margin spot-action-bar--action"
        (click)="back.emit()"
      >{{ text.backButton }}</button>
      <button class="button button_no-margin -highlight spot-action-bar--action">{{ text.nextButton }}</button>
    </div>
  </div>
</form>
