<form
  class="spot-modal form -vertical confirm-form-submit--modal password-confirm-dialog--modal loading-indicator--location"
  data-indicator-name="modal"
  (submit)="confirmAndClose($event)"
>
  <div class="spot-modal--header">{{text.title}}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body request-for-confirmation--form form">
    <div class="form--field -required">
      <label class="form--label"
             for="request_for_confirmation_password"
             [attr.aria-label]="text.field_description"
             [textContent]="text.password">
      </label>
      <div class="form--field-container">
        <div class="form--text-field-container">
          <input type="password"
                 #passwordConfirmationField
                 [(ngModel)]="password_confirmation"
                 id="request_for_confirmation_password"
                 name="request_for_confirmation_password"
                 class="form--text-field">
        </div>
      </div>
      <div class="form--field-instructions" [textContent]="text.field_description"></div>
    </div>
  </div>

  <div class="spot-modal--footer spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        class="confirm-form-submit--continue button button_no-margin -highlight spot-action-bar--action"
        [textContent]="text.confirm_button"
        [disabled]="!passwordValuePresent()">
        >
      </button>
    </div>
  </div>
</form>
