<form
  name="sendUserInviteForm"
  (submit)="onSubmit($event)"
  class="op-ium-summary"
>
  <div class="spot-modal--header">{{text.title()}}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body op-form">
    <op-form-field [label]="text.projectLabel">
      <p slot="input">{{ project.name }}</p>
    </op-form-field>
    <div class="op-ium-summary__row">
      <op-form-field [label]="text.principalLabel[type]">
        <p slot="input">{{ principal?.name }}</p>
      </op-form-field>
      <op-form-field [label]="text.roleLabel()">
        <p slot="input">{{ role.name }}</p>
      </op-form-field>
    </div>
    <op-form-field
      [label]="text.messageLabel"
      *ngIf="type !== PrincipalType.Placeholder && message"
    >
      <p slot="input" class="op-ium-summary-message">{{ message }}</p>
    </op-form-field>
  </div>

  <div class="spot-modal--footer spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button button_no-margin spot-action-bar--action"
        (click)="back.emit()"
      >{{ text.backButton }}</button>
      <button
        class="button button_no-margin -highlight spot-action-bar--action"
      >{{ text.nextButton() }}</button>
    </div>
  </div>
</form>
