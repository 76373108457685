<div
  class="spot-modal loading-indicator--location"
  data-indicator-name="modal"
>
  <div class="spot-modal--header">{{text.title}}</div>

  <div class="spot-divider"></div>

  <div class="ngdialog-body spot-modal--body">

    <op-enterprise-banner
      *ngIf="eeShowBanners"
      [linkMessage]="text.upsale_link"
      [textMessage]="text.upsale_text"
      [moreInfoLink]="text.more_info_link"
      class="grid--add-widget-ee"
      opReferrer="grids#add-widget"
    ></op-enterprise-banner>

    <div
      *ngFor="let widget of selectable;trackBy: trackWidgetBy"
      (click)="select($event, widget)"
      [id]="widget.identifier"
      [textContent]="widget.title"
      class="grid--addable-widget"
    ></div>
  </div>
</div>
